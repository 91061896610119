import React from 'react';
import Banner from '../components/About/Banner';
import Contact from '../components/Home/Contact';
import Footer from '../components/Footer';



function ContactPage() {
  return (
    <>
      <Banner pageName="Contact Us" />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d384.42063820382873!2d31.005435358790233!3d-17.774614542882492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931af44e82f90ed%3A0x2a9e3d9b5192a10f!2s16%20Ely%20Rd%2C%20Harare!5e0!3m2!1sen!2szw!4v1730475253116!5m2!1sen!2szw"
        width="100%"
        height="450"
        allowfullscreen=""
        title="Confide Pay: Your Trusted Payment Solution for Secure Transactions"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <Contact />
      <Footer />
    </>
  );
}

export default ContactPage