/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Paragraph from '../styled/Paragraph';
import { Link } from 'react-router-dom';
import {FaLinkedinIn, FaFacebookF} from 'react-icons/fa';



const Wrapper = styled.footer`
background:${props => props.theme.color.lightblue};
padding:5%;
color:#ffffff;
    @media(min-width:1200px){
        padding:5% 10%;
    }
    p{
        color:#ffffff;

    }
    .top-footer{
        @media(min-width:992px){
            display:flex;
            justify-content: space-between;
           
        }
        .top-left{
            @media (min-width:992px) {
                width:450px;
            }
            .top-img{
                display: flex;
                align-items:center;
                img{
                    width:100px;
                    transform: translateX(-20%);
                }
                h5{
                    font-size:18px;
                    font-family:${props => props.theme.fam.regular};
                }
            }
            p{
                margin-bottom:20px;
            }
        }
        .top-right{
             @media (min-width:992px) {
                width:450px;
                margin-bottom:40px;
                
            }
            a{
                display: flex;
                padding:20px 0;
                text-decoration:none;
                color:#ffffff;
            }
            .top-right-links{
                display:flex;
                justify-content: space-between;
                flex-wrap:wrap;
                margin-bottom:30px;
                a{
                    width:45%;
                    text-transform:uppercase;
                }
                @media(min-width:768px){
                    
               max-width:500px;
               a{
                width:unset;
               }
                }
             
            }
            .social{
                display:flex;
                    @media (min-width:992px) {
                    justify-content: flex-end;
            }
                a{
                    border:1px solid #ffffff;
                    height:25px;
                    width:25px;
                    padding:5px;
                    display: flex;
                    align-items:center;
                    justify-content:center;
                    text-decoration:none;
                    color:#ffffff;
                }
            }
        }
    }
    .bottom-footer{
        margin-top:20px;
        @media(min-width:768px){
            display:flex;
            justify-content:space-between;
        }
        p{
            margin-bottom:20px;
        }
    }
`

function Footer() {

    const year = moment().year();
  return (
    <Wrapper>
        <div className="top-footer">
            <div className="top-left">
             
                <Paragraph>ConfidePay (Pvt) Ltd (formally ECA Consultancy) is a leading provider of payroll solutions in Zimbabwe. We offer payroll software, payroll bureau services, training and payroll support.</Paragraph>

            </div>
            <div className="top-right">
                <div className="top-right-links">
                    <Link to='/'>Home</Link>
                    <Link to='/about'>About Us</Link>
                    <Link to='/faqs'>Faqs</Link>
                    <Link to='/blogs'>Blogs</Link>
                    <Link to='/contact'>Contact</Link>
                </div>
                <div className="social">
                    <a href="https://www.linkedin.com/company/confidepay/">
                        <FaLinkedinIn />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100067299039556">
                        <FaFacebookF />
                    </a>
                </div>
            </div>
        </div>
        <div className="bottom-footer">
            <Paragraph>© All rights reserved. {year} Confide Pay . Built by Revixions Creative Studio</Paragraph>
            <Paragraph>All Rights Reserved.</Paragraph>
        </div>
    </Wrapper>
  )
}

export default Footer